<template>
  <q-page />
</template>

<script>
import { supplier_handled } from 'api/book'
import { bookReturn } from 'api/bikehire'
import loading from 'utils/loading'
import i18n from 'i18n'

export default {
  props: ['token', 'requester', 'journey'],
  beforeRouteEnter (to, from, next) {
    next(vm => {
      const partner = vm.$store.getters['partner']
      const paymentParameters = vm.$store.getters['payment/params']
      loading.start({
        message: i18n.t('loading.completing_booking'),
        partnerSlug: partner.slug,
        spinnerColor: partner.hex
      })

      if (paymentParameters.isBikeReturn) {
        return bookReturn(to.params.token)
      }

      const params = {
        payment_method: 'supplier_handled'
      }

      const { journey, travelType, reasonFor, labels, acceptance } = paymentParameters

      if (journey.reference) params.journey_reference = journey.reference
      if (journey.name) params.journey_name = journey.name
      if (travelType) params.travel_type = travelType
      if (reasonFor) params.reason_for = reasonFor
      if (labels) params.labels = labels
      if (acceptance) params.acceptance = acceptance

      supplier_handled(paymentParameters.token, params)
        .then(response => {
          vm.$router.push({
            name: 'dashboard',
            params: {
              booking_reference: response.data.reference,
              content_type: response.data.content_type
            }
          })
        })
        .catch(response => {
          loading.stop()
          vm.$q.notify({
            message: i18n.t('error.default') + '. ' + i18n.t('error.try_again'),
            icon: 'fas fa-times-circle',
            color: 'negative'
          })
          next(false)
        })
    })
  }
}
</script>
